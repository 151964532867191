@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./assets/fonts/fonts.css");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
    @apply text-ocean-blue;
  }

  h1 {
    @apply text-4xl md:text-5xl font-semibold;
    @apply font-serif;
  }
  h2 {
    @apply text-3xl font-semibold;
  }

  h3 {
    @apply text-2xl font-semibold;
  }

  h4 {
    @apply text-lg font-semibold uppercase;
    letter-spacing: 1px;
  }

  .invert .loader path {
    @apply text-white;
  }

  .fsu-table th {
    @apply text-blue-gray text-left;
  }

  .fsu-table tbody tr {
    @apply bg-medium-gray even:bg-white;
  }

  .fsu-table tbody tr:hover {
    @apply bg-soft-mint bg-opacity-30;
  }

  * {
    @apply text-ocean-blue;
  }

  /* Mobile menu */
  div.mobile-menu-box-shadow {
    box-shadow: -3px 20px 20px 0px rgba(0, 0, 0, 0.1);
  }

  /* Buttons & Links */
  button.btn-full,
  button.btn-empty {
    @apply font-bold !important;
    letter-spacing: 1px;
  }

  button:focus {
    outline: 0;
  }

  button.btn-empty {
    @apply text-ocean-blue;
  }

  .file-upload-icon path {
    @apply text-dark-gray;
  }
  .file-upload-label:hover .file-upload-icon path {
    @apply text-soft-mint;
  }

  .remove-confirmation-show-btn path {
    @apply text-dark-gray;
  }
  .remove-confirmation-show-btn:hover path {
    @apply text-ocean-blue;
  }

  .vid-message .remove-confirmation-show-btn path {
    @apply text-white text-opacity-50;
  }

  .vid-message .remove-confirmation-show-btn:hover path {
    @apply text-opacity-100;
  }

  button.btn-empty:hover,
  button.btn-empty:hover > *,
  button.btn-empty:hover svg path {
    @apply bg-ocean-blue text-white;
  }

  button.btn-full:hover,
  button.btn-full:hover > *,
  button.btn-full:hover svg path {
    @apply bg-ocean-blue-dark text-white;
  }
  button.btn-full:disabled:hover,
  button.btn-full:disabled:hover svg path {
    @apply bg-ocean-blue opacity-60;
  }

  /* Extra X-BUTTON styles */
  button.x-button svg {
    @apply m-0.5;
  }

  button.x-button svg path {
    @apply text-ocean-blue opacity-20 transition;
  }

  button.x-button:hover svg path {
    @apply opacity-90;
  }

  button.icon-button-link:hover svg path {
    @apply text-dull-mint;
  }

  a,
  a span,
  a svg path {
    @apply text-blue-gray;
  }

  a:hover,
  a:hover span,
  a:hover svg path,
  a:focus,
  a:focus svg path,
  a:focus span {
    @apply text-ocean-blue-dark;
  }

  .pulsate {
    -webkit-animation: pulsate 0.8s ease-in-out infinite both;
    animation: pulsate 0.8s ease-in-out infinite both;
  }

  /**
 * ----------------------------------------
 * animation pulsate
 * ----------------------------------------
 */
  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
      opacity: 0.8;
    }
    80% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      opacity: 0;
    }
  }
  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
      opacity: 0.8;
    }
    80% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      opacity: 0;
    }
  }

  /* FORMS */

  input:focus,
  input:focus-visible,
  select:focus,
  select:focus-visible {
    outline: 0 !important;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  /* Input type text etc*/
  .form-textarea-wrapper > textarea {
    @apply border-l-4 border-blue-gray bg-medium-gray pl-4 text-ocean-blue;
    border-radius: 0;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"] {
    @apply border-l-4 border-blue-gray bg-medium-gray h-12 pl-4 text-ocean-blue;
    border-radius: 0;
  }

  /* TABLE */

  table {
    @apply border-l-4 border-blue-gray text-blue-gray;
  }

  table th {
    @apply uppercase;
    letter-spacing: 1px;
  }

  table th,
  table td {
    @apply py-2 px-4;
  }

  /* SEARCH & FILTER */

  .filter-search-wrapper {
    @apply md:flex w-full mt-12 mb-8;
  }

  .filter-search-wrapper h4 {
    @apply m-0 mb-2 text-base;
  }

  .search-box input {
    @apply bg-white border-ocean-blue border w-full p-2 !important;
  }

  .search-box input:focus {
    @apply border-l-4 !important;
    outline: 0;
  }

  .search-box input::placeholder {
    @apply text-ocean-blue;
  }

  /* PAGE LAYOUTS */

  /* Unauthorized access */
  .ua-content-wrapper {
    max-width: 800px;
  }

  /* Module */
  .module-content-wrapper {
    @apply m-4 sm:m-10 md:m-12 xl:m-24;
    max-width: 1200px;
  }

  .module-block {
    @apply pb-4;
  }

  .intro {
    @apply pb-8;
    max-width: 900px;
  }

  .module-extra {
    @apply mt-8;
  }

  h3,
  h4 {
    @apply mb-4;
  }

  .module-extra svg.svg-inline--fa {
    @apply h-6 relative;
    top: 4px;
  }

  .module-video {
    @apply space-y-4;
  }

  /* New organisation */

  .new-org-fields input {
    @apply bg-white;
  }

  .new-org-fields .new-org-fields-remove-btn:hover path {
    @apply text-red;
  }

  .license-date-input-wrapper > label {
    @apply mb-4 block !important;
  }

  .checkboxlist.modules div.module-options {
    height: 320px;
  }

  .checkboxlist label {
    @apply block pl-8 relative;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    padding-right: 2rem;
    flex: 1 1 40px;
  }

  .checkboxlist.modules label {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
  }

  .checkboxlist input[type="checkbox"] {
    display: none;
  }

  .checkboxlist .fancy-checkbox {
    @apply absolute border-2 border-blue-gray !important;
    top: 2px;
    left: 0;
    border-radius: 100%;
    font-size: 1.3rem;
    font-weight: normal;
    height: 20px;
    width: 20px;
  }

  .checkboxlist input[type="checkbox"]:checked + .fancy-checkbox {
    border: none;
  }

  .checkboxlist input[type="checkbox"]:checked + .fancy-checkbox .fc-icon {
    @apply block;
  }

  /* fancy checkboxes */
  /*
  .checkboxlist > div {
    flex-direction: column;
    height: 320px;
  } */

  /* label.form-checkbox {
    @apply block pl-8 relative;
    cursor: pointer;
    padding-right: 2rem;
    flex: 1 1 40px;
  } */

  .form-checkbox input[type="checkbox"] {
    display: none;
  }

  .form-checkbox .fancy-checkbox {
    @apply absolute border-2 border-blue-gray !important;
    top: 2px;
    left: 0;
    border-radius: 100%;
    font-size: 1.3rem;
    font-weight: normal;
    height: 20px;
    width: 20px;
  }

  .form-checkbox input[type="checkbox"]:checked + .fancy-checkbox {
    border: none;
  }

  .form-checkbox input[type="checkbox"]:checked + .fancy-checkbox .fc-icon {
    @apply block;
  }

  /* OTHER */

  /* Conversation number */
  .conversaion-message-number {
    @apply text-white text-center inline-block relative mr-2 bg-red;
    border-radius: 100%;
    font-weight: 700;
  }

  .cmn-small.conversaion-message-number {
    width: 10px;
    height: 10px;
  }

  .cmn-large.conversaion-message-number {
    width: 22px;
    height: 22px;
    font-size: 13px;
    padding-top: 1px;
    top: -2px;
  }

  /* FA icons */
  .icon-button-link svg.svg-inline--fa {
    @apply mr-2;
  }

  .fsu-extra-material td {
    @apply align-top;
  }
  .fsu-extra-material p {
    @apply mb-2;
  }

  .fsu-extra-material p path {
    @apply mb-2 text-fresh-mint text-xs mr-2;
  }
  .fsu-extra-material p svg {
    @apply mr-1;
  }
}

.AlertBar {
  position: relative;
}
.AlertBar svg path {
  fill: white;
}

.AlertBar .x-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
