.base {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: visible;
  position: relative;
  margin: 2.4rem 0.45rem;
}

.base h1 {
  font-size: 2.6rem;
}
.base svg {
  display: flex;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  transform: rotate(-90deg);
}
.base svg.clickable {
  cursor: pointer;
  transition: 100ms ease-out;
}

.base svg.clickable:hover {
  transform: scale(1.08) rotate(-90deg);
}

.base.withCaption {
  margin-bottom: 34px;
}

.base div.centerText {
  font-size: 1.25rem;
  font-weight: 700;
  position: absolute;
  pointer-events: none;
}
.base div.centerText p {
  padding: 0 24px;
}
.base div.bottomText {
  font-size: 0.9rem;
  font-weight: 700;
  position: absolute;
  bottom: -38px;
}
