/* Fonts */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-Regular.woff2") format("woff2"),
    url("./SourceSansPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-SemiBoldItalic.woff2") format("woff2"),
    url("./SourceSansPro-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-SemiBold.woff2") format("woff2"),
    url("./SourceSansPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-Italic.woff2") format("woff2"),
    url("./SourceSansPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./SourceSerifPro-Light.woff2") format("woff2"),
    url("./SourceSerifPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./SourceSerifPro-Regular.woff2") format("woff2"),
    url("./SourceSerifPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./SourceSerifPro-SemiBold.woff2") format("woff2"),
    url("./SourceSerifPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./SourceSerifPro-SemiBoldIt.woff2") format("woff2"),
    url("./SourceSerifPro-SemiBoldIt.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
