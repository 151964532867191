button.delete path {
  @apply text-dark-gray;
}

button.delete:hover path {
  @apply text-red;
}

.vid-message:hover {
  @apply bg-opacity-100;
}

input[type="range"] {
  /* width: 584px; */
  margin: 0 0;
}
